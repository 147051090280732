<i18n locale="th" lang="yaml" >
page.title : "Privacy Policy"
</i18n>

<template>
	<div id="privacy_pulicy_newuser_page">
		<ConsentDialog/>
	</div>
</template>

<script>
import ConsentDialog from "@/src/components/user/ConsentDialog.vue"
import axios from "axios"
import {  mapGetters } from 'vuex'

export default {
	components : {
		 ConsentDialog
	} ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	data() {
		return {

		}
	} ,
	computed : {

	} ,
	mounted() {
		this.getConsentAPI()
	} ,
	methods : {
		...mapGetters('app',['changeDefaultPathByDevice']),
		async getConsentAPI() {
			const response = await axios.get("/api/users/consent")

			if(!response.data.data.isConsent) return

			this.$router.push({name: this.changeDefaultPathByDevice()})
		},
	}
}
</script>
