import { render, staticRenderFns } from "./ConsentDialog.vue?vue&type=template&id=95c7c12c&scoped=true"
import script from "./ConsentDialog.vue?vue&type=script&lang=js"
export * from "./ConsentDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95c7c12c",
  null
  
)

/* custom blocks */
import block0 from "./ConsentDialog.vue?vue&type=custom&index=0&blockType=i18n&locale=th&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports